import { useEffect, useState } from 'react';
import myFirebase, { auth } from '../firebase';


/**
 *  Try to fetch userData when component mounted
 *  @param {number} ms milliseconds between trying, default 300ms
 *  @param {number} times number of try times, default 5 times
 */
export const useFetchUserData = (ms = 300, times = 5) => {
    const [userData, setUserData] = useState({status: 'loading', user:'unauthorized'});

    useEffect(() => {
        return promiseUserData()
    }, []);

    const promiseUserData = () => {
        return new Promise(() => {
            return auth.onAuthStateChanged(user => {
                if (user) {
                    let trying = 0;
                    const intervalId = setInterval(async () => {
                        trying++;
                        await myFirebase.db.collection('users').where('email', '==', user.email).get()
                            .then(snap => {
                                clearInterval(intervalId);
                                if (!snap.empty) {
                                    snap.forEach(userData => {
                                        setUserData({status: 'done', user: 'authorized', ...userData.data()})
                                    });
                                }else {
                                    setUserData({status: 'done', user: 'unauthorized'})
                                }
                            });
                        if (trying === times) {
                            clearInterval(intervalId);
                            setUserData({status: 'done', user: 'unauthorized'})
                        }
                    }, ms);
                }
                setUserData({status: 'done', user: 'unauthorized'})
            });
        });
    };
    return userData;
};