import { useEffect } from 'react';
import './useEnableBgByLocation.scss';


/**
 * enable background only for specific routes
 * no parameters!
 */
export const useChangeBackground = (authorizedUser) => {

    const backgroundImage1280_720 = 'https://firebasestorage.googleapis.com/v0/b/gefenschool-b00c3.appspot.com/o/projectImages%2FbackgroundPeople1280_720layers.jpg?alt=media&token=fbda1581-580c-4aa6-b646-bdcbd23ee41b';

    const clearBackgroundImg = (selector) => {
        selector.style.overflow = 'auto';
        selector.style.backgroundColor = '#fff';
        selector.style.backgroundImage = 'none';
    };

    const applyBackgroundImg = (body) => {
        body.style.backgroundImage = `url(${ backgroundImage1280_720 })`;
        body.style.overflow = 'hidden';
    };
    const selector = document.getElementsByTagName('body')[0];
    const makeTransparent = () => selector.classList.add('makeTransparentExceptSelected');
    const removeTransparent = () => selector.classList.remove('makeTransparentExceptSelected');
    const addEffect = () => {
        applyBackgroundImg(selector);
        makeTransparent();
    };
    const removeEffect = () => {
        clearBackgroundImg(selector);
        removeTransparent();
    };

    useEffect(() => {
        if (authorizedUser) {
            removeEffect()
        } else {
            selector.style.backgroundSize = 'cover';
            addEffect()
        }
    }, [authorizedUser]);

};