import React, { useContext, useState, useEffect, useMemo, lazy, Suspense } from 'react';
import myFirebase, { auth } from '../firebase';
import firebase from 'firebase/app';
import errorMessages from '../utils/errorMessages';
import { compactHash } from '../utils/utils';
import { useFetchUserData } from '../hooks/useFetchUserData';
import Swal from 'sweetalert2';

const AuthContext = React.createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const userData = useFetchUserData()

    useEffect(() => {
        if (userData)  setCurrentUser(userData)
    }, [userData?.status, userData?.user])

    useEffect(() => {
        if (!error) {
            setLoading(false)
        }
    }, [])

    const clear = () => {
        setTimeout(() => {
            setError(false);
            setSuccess(false);
            setLoading(false);
        }, 0);
    };

    const signUp = async values => {
        try {
            const {password, ...rest} = values;
            const email = values.email.toString().toLowerCase();
            const authUser = await auth.createUserWithEmailAndPassword(email, password);
            const id = authUser.user.uid;
            const user = {...rest, email, id, courses: {}};
            await myFirebase.db.collection('users').doc(id).set(compactHash(user));
            window.location.replace('/')
        } catch (error) {
            setError(errorMessages(error.code));
            setLoading(false);
            clear();
        }
    };

    const signIn = async (data) => {
        setLoading(true);
        setSuccess(false);
        const email = data.email.toString().toLowerCase();
        try {
            await auth.signInWithEmailAndPassword(email, data.password);
            setLoading(false);
            setSuccess(true);
            clear();
        } catch (error) {
            console.log('signIn res catch: ', error)
            await Swal.fire({
                icon: 'error',
                title: 'Signin-error',
                text: error
            })
            setLoading(false);
            clear();
        }
    };

    const signOut = async () => {
        setLoading(true);
        try {
            await auth.signOut();
            setCurrentUser(null);
            setLoading(false);
            clear();
            window.location.reload();
        } catch {
            setLoading(false);
            clear();
        }
    };

    const updateAuthsUser = async values => {
        setLoading(true);
        setSuccess(false);
        try {
            const {email, oldPassword, password} = values;
            const user = auth.currentUser;
            const newEmail = user.email === email ? null : email;

            if (newEmail || password) {
                const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
                await user.reauthenticateWithCredential(credential);
                if (newEmail) await user.updateEmail(newEmail);
                if (password) await user.updatePassword(password);
            }
            await myFirebase.db.collection('users').doc(user.uid).update(compactHash({email, id: user.uid}));
            setLoading(false);
            setSuccess(true);
            setCurrentUser({...currentUser, email: newEmail || currentUser.email});
            clear();
        } catch (error) {
            setError(errorMessages(error.code));
            setLoading(false);
            clear();
        }
    };

    const updateDetailsUser = async values => {
        setLoading(true);
        setSuccess(false);
        try {
            const user = auth.currentUser;
            await myFirebase.db.collection('users').doc(user.uid).update(compactHash(values));
            setLoading(false);
            setSuccess(true);
            clear();
            setCurrentUser({...currentUser, ...values});
        } catch (error) {
            setError(errorMessages(error.code));
            setLoading(false);
            clear();
        }
    };

    const forgotPassword = async ({email}) => {
        setLoading(true);
        setSuccess(false);
        try {
            await auth.sendPasswordResetEmail(email);
            setLoading(false);
            setSuccess(true);
            clear();
        } catch {
            setError(errorMessages(error.code));
            setLoading(false);
            clear();
        }
    };

    const value = useMemo(
        () => ({
            currentUser,
            signUp,
            signIn,
            signOut,
            updateDetailsUser,
            updateAuthsUser,
            forgotPassword,
            loading,
            error,
            success,
        }),
        [currentUser, loading, error, success],
    );


    return (
        <AuthContext.Provider value={ value }>
            { children }
        </AuthContext.Provider>
    );
}
