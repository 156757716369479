import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CourseProvider } from './contexts/CourseContext';
import { CacheProvider } from './contexts/CacheContext';
import { StorageProvider } from './contexts/StorageContext';
import classes from './App.scss';
import 'antd/dist/antd.css';
import { Layout, ConfigProvider } from 'antd';

import ScrollToTop from './components/common/ScrollToTop';
import useMediaQuery from './hooks/useMediaQuery';
import myFirebase from './firebase';
import { compactHash } from './utils/utils';
import { useFetchUserData } from './hooks/useFetchUserData';
import { Spinner } from './components/common/spinner/spinner';
import { useChangeBackground } from './hooks/useChangeBackground';

const {Content} = Layout;

const TopBarLazy = lazy(() => import('./components/portal/TopBar/TopBar'));
const RoutesLazy = lazy(() => import('./components/portal/Routes'));

const App = () => {
    const {isMobile, isTablet, isLaptop} = useMediaQuery();
    const [appReady, setReady] = useState(false);

    const userData = useFetchUserData();

    useEffect(() => {
        Promise.race([
            new Promise(resolve => {
                if (userData.user === 'authorized' && userData.status === 'done') {
                    resolve(true)
                }
            }),
            new Promise(resolve => {
                if (userData.status === 'done' || (userData.status === 'done' && userData.user === "unauthorized")) {
                    setTimeout(() => resolve(true), 500);
                }
            })
        ])
            .then(bool => setReady(bool))

    }, [userData])

    const getMargin = () => {
        if (isMobile) return '55px';
        if (isTablet) return '60px';
        if (isLaptop) return '65px';
        return '75px';
    };
    useChangeBackground(userData.user === 'authorized');

    const spinner = () => <Spinner size={ 300 } style={ {position: 'absolute', top: '30%'} }/>;

    return (
        <>
            <Router>
                <ScrollToTop/>
                <AuthProvider>
                    <CourseProvider>
                        <StorageProvider>
                            <CacheProvider>
                                <ConfigProvider direction="rtl">
                                    <Layout className={ classes.layout }>
                                        {
                                            appReady && (
                                                <Suspense fallback={ spinner() }>
                                                    <TopBarLazy/>
                                                    <Content style={ {marginTop: getMargin()} }>
                                                        <div className="site-layout-content">
                                                            <RoutesLazy/>
                                                        </div>
                                                    </Content>
                                                </Suspense>
                                            )
                                        }
                                    </Layout>
                                </ConfigProvider>
                            </CacheProvider>
                        </StorageProvider>
                    </CourseProvider>
                </AuthProvider>
            </Router>
        </>
    );
};

export default App;
