import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';


const app = firebase.initializeApp({
  apiKey: String(process.env.REACT_APP_FIREBASE_API_KEY),
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID)
})

export const auth = app.auth()
const db = firebase.firestore();
const fieldPath = firebase.firestore.FieldPath;
const storage = firebase.storage();
const myFirebase = { db, storage, fieldPath }
export default myFirebase
