import React, { useContext, useState, useEffect, useMemo } from "react";
import myFirebase from "../firebase";
import errorMessages from "../utils/errorMessages";
const { db, storage } = myFirebase

const CourseContext = React.createContext();

export const useCourse = () => {
  return useContext(CourseContext);
};

export const CourseProvider = (props) => {
  const { children } = props;
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    try {
      const coursesSnapshot = await db.collection("courses").orderBy("createdAt").get();
      const storageRef = storage.ref()
      const promises = []
      const roundImagesPromises = []
      const coursesList = []
      coursesSnapshot.forEach(course => {
        const data = course.data()
        coursesList.push({ ...data, key: data.id })
        const listRef = storageRef.child(`CoursesImages/${data.id}`);
        const roundImageRef = storageRef.child(`CoursesRoundImages/${data.id}`);
        promises.push(listRef.getDownloadURL())
        roundImagesPromises.push(roundImageRef.getDownloadURL())
      });
      const urlsList = await Promise.all(promises.map(p => p.catch(() => false)))
      const roundImagesUrlsList = await Promise.all(roundImagesPromises.map(p => p.catch(() => false)))
      urlsList.forEach((imageUrl, index) => coursesList[index] = { ...coursesList[index], imageUrl })
      roundImagesUrlsList.forEach((roundImageUrl, index) => coursesList[index] = { ...coursesList[index], roundImageUrl })
      setCourses(coursesList)
      setLoading(false)
    } catch (error) {
    }
  };

  const addCourse = async ({ course }) => {
    try {
      await db
        .collection("courses")
        .doc(course.id)
        .set(course);

      setCourses([...courses, course])
    } catch {
      console.log('Error while adding course')
    }
  }

  const updateCourse = async (course) => {
    try {
      await db
        .collection("courses")
        .doc(course.id)
        .update(course);

      setCourses(courses.map(c => (c.id === course.id ? course : c)))
    } catch {
      console.log('Error while updating course')
    }
  }

  const addLessonToCourse = async ({ currentLessons, counter, lessonId, courseId, lesson }) => {
    try {
      await db
        .collection("lessons")
        .doc(lessonId)
        .set(lesson);

      await db
        .collection("courses")
        .doc(courseId)
        .update({ "lessons": { ...currentLessons, [counter]: lessonId } });

      const newCourses = courses.map(course => {
        if (course.id === courseId) {
          return ({ ...course, lessons: { ...currentLessons, [counter]: lessonId } })
        } else return course
      })
      setCourses(newCourses)
    } catch { }
  }

  const massDelete = async (selectedRowsValues) => {
    setLoading(true)
    setError(false)
    try {
      const batch = db.batch()
      selectedRowsValues.forEach((deleteCourse) => {
        const deleteCourseRef = db.collection('courses').doc(deleteCourse.id)
        Object.values(deleteCourse.lessons || {}).forEach((deleteLesson) => {
          const deleteLessonRef = db.collection('lessons').doc(deleteLesson)
          batch.delete(deleteLessonRef)
        })
        batch.delete(deleteCourseRef)
      })
      await batch.commit()
      getCourses()
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
    }
  }

  const value = useMemo(
    () => ({
      courses,
      loading,
      error,
      addCourse,
      addLessonToCourse,
      updateCourse,
      massDelete
    }),
    [courses, loading, error]
  );

  return (
    <CourseContext.Provider value={value}>{children}</CourseContext.Provider>
  );
};
