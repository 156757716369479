import { useEffect, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import './_spinner.scss'

export const Spinner = ({loading = true, color = 'black', size = 150,  style, speed = 0.7}) => {
    const [loadingState, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            setLoading(true)
        }else {
            setLoading(false)
        }
    }, [loading])

    return (
        <div className="spinner-wrapper">
            <div style={ style ? {...style} : null }>
                <ClipLoader color={color} loading={loadingState} size={size} speedMultiplier={speed}/>
            </div>
        </div>
    );
}